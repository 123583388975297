import './style.css'
import wrapWithProvider from './wrap-with-provider'
import { TrackingCase } from './utils/trackingCases'
import React, { useEffect } from 'react'
import { tracker } from './src/systemLogs'
import { navigate } from 'gatsby'
import { ENDPOINTS, ROUTES } from './src/constants'
import axios from './src/axios'
import { UAParser } from 'ua-parser-js'

export const wrapRootElement = wrapWithProvider

const TrackingComponent = ({ location }) => {
  useEffect(() => {
    tracker.track(TrackingCase.PageNavigation, `visit ${location.pathname}`, {
      url: location.href,
    })
  })
  return null
}

export const wrapPageElement = ({ element, location, props }) => (
  <React.Fragment>
    <TrackingComponent location={location} {...props} />
    {element}
  </React.Fragment>
)

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('old pathname : ', prevLocation ? prevLocation.pathname : null)
  // console.log('new pathname : ', location.pathname)
  if (window.localStorage.getItem('user_id')) {
    let profile = JSON.parse(window.localStorage.getItem('profile'))
    if (profile.email) {
      if (sessionStorage.getItem('activeOnSite') === null) {
        sessionStorage.setItem('activeOnSite', true)
        axios.patch(`${ENDPOINTS.klaviyoTrigger}`, {
          email: profile.email,
          event: 'Active on Site',
          body: {
            'Active on Site Value': '0.00',
            browser: new UAParser().getBrowser().name,
            os: new UAParser().getOS().name,
            page: location.href,
          },
        })
      }
    }
    if (profile?.firstName && profile?.lastName && profile?.username) {
      window.localStorage.removeItem('redirectedFromRouting')
    } else {
      if (
        prevLocation?.pathname !== location?.pathname ||
        prevLocation?.pathname == null
      ) {
        if (
          prevLocation?.pathname === '/join' &&
          location?.pathname === '/user/personal-info'
        ) {
        } else {
          window.localStorage.setItem('redirectedFromRouting', true)
        }
        if (window.localStorage.getItem('howItWorksStatus')) {
          navigate('/app/personal-info-app')
        } else {
          navigate(ROUTES.personalInfo)
        }
      }
    }
  }
}

export const onClientEntry = () => {
  let pathSplits = window.location.pathname.split('/')
  let pageName = pathSplits[1]
  if (pageName && pageName.toLocaleLowerCase() !== pageName) {
    pathSplits[1] = pageName.toLocaleLowerCase()
    window.location.replace(pathSplits.join('/'))
  }
  // if (!(performance.navigation.type === performance.navigation.TYPE_RELOAD)) {
  //   const user_id = window.localStorage.getItem('user_id')
  //   try {
  //     setTimeout(() => {
  //       if (user_id)
  //         axios.get(ENDPOINTS.increaseVisitsByOne.replace(':id', user_id))
  //     }, 5000)
  //   } catch (error) {}
  // }
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
